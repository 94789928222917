export const DefaultDisclaimerText = `
<h1>Importante aviso sobre el uso a la verificación facial</h1>

<p>
    Este sistema de verificación está destinado a protegerte y asegurar el acceso y uso correcto de los servicios que estás procurando. 
    La verificación biométrica es un proceso delicado y, por lo tanto, las instrucciones dadas deben ser seguidas para garantizar un óptimo funcionamiento. 
    A continuación, se describen los pasos y condiciones necesarios para el correcto uso de este sistema.
</p>

<p>
    El uso inapropiado, malintencionado o cualquier intento de manipular el sistema puede resultar en la suspensión de tu cuenta, notificación a las autoridades competentes y posibles cargos legales.
</p>

<p>
    Al continuar con la verificación facial, aceptas que su propósito es únicamente tu autenticación y seguridad, así como proveer al prestatario del servicio que procuras de una garantía de tu identidad:
</p>

<h2>Condiciones de Uso</h2>

<p>El proceso de verificación de identidad está destinado a usuarios legítimos.</p>

<h3>Requisitos de Verificación</h3>

<ul>
    <li>Por favor, asegúrate de que tu entorno esté bien iluminado para que la cámara pueda capturar tu rostro.</li>
    <li>Elimina cualquier obstrucción en tu cara, como gafas de sol, gorras, mascarillas, etc.</li>
    <li>Alinea tu rostro dentro del área permitida en la pantalla y mantén una postura natural.</li>
    <li>La cámara debe tener acceso estable a la red para que se procese correctamente la verificación.</li>
    <li>Verifica que tu conexión a la red no esté interrumpida.</li>
    <li>Durante el proceso, te podrían pedir que gires ligeramente tu cabeza o que mantengas una expresión neutral para completar la verificación de manera segura.</li>
</ul>

<h3>
    Seguridad y Privacidad
</h3>

<p>
    Toda la información biométrica está protegida bajo estrictas normas de seguridad y es utilizada únicamente para confirmar tu identidad.
    No vendemos ni compartimos la información con terceros.
    Todo el sistema está regulado por las leyes y normativas de protección de datos personales.
</p>

<h3>Proceso de Verificación</h3>

<p>
    Si la cámara no puede verificar tu identidad por falta de luz, problemas con la cámara o una conexión inestable, el sistema te guiará en cómo intentarlo nuevamente.
    En caso de errores recurrentes, puedes contactarnos para recibir asistencia. 
</p>

<h3>
    Uso Responsable
</h3>

<p>
    Este sistema fue creado para proteger tu seguridad y garantizar que solo tú puedas acceder a los servicios.
    Los abusos están prohibidos, al igual que la usurpación de identidad.
    Cualquier actividad sospechosa será investigada y podría resultar en la suspensión de acceso a la plataforma y sus servicios. 
    Al proceder, aceptas los términos y condiciones, así como el uso de tu información biométrica para fines de autenticación y seguridad.
</p>
`;
